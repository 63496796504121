import axios from 'axios';


export async function domain({next}) {
	await axios.get(`${process.env.VUE_APP_API}/tenants/domain`)
	.then(res => {
		const domain = res.data.data
		if(!domain.includes(window.location.origin)) return next("/404");

	})	
	.catch(err => {
		console.log(err.response.data.errors);
	})
  

}