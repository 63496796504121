export default function() {
  return {
    user: [],
    show: [],
    users: [],
    classes: [],
    errors: [],

    tenant:[],
    tenants: [],

    customization: {},

    alert: { show: false, text: ''},
    
    player: {
      action: null,
      duration: 0,
    },

    drawer: false,
    replybox: [],

    course: [],
    other_courses: [], 
    free_courses: [],
    paid_courses: [],
    
    registration_tables: [],
    cart: [],
  }
}