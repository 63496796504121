import Vue from 'vue'
import VueRouter from 'vue-router'

const { push } = VueRouter.prototype

const { isNavigationFailure, NavigationFailureType } = VueRouter

// VueRouter.prototype.push = function (location) {
//   return push.call(this, location).catch(error => {
//     if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
//       throw Error(error)
//     }
//   })
// }


Vue.use(VueRouter)

import middlewarePipeline from './middleware-pipeline'
import { domain } from './domain-middleware'
import { EducatRoutes } from './auth'
import { LandingRoutes } from './landing'
import { TeacherRoutes } from './teacher'
import { UserRoutes } from './user'
import { AdminRoutes } from './admin'
import { SuperRoutes } from './super'

const routes = [
  ...LandingRoutes,
  ...EducatRoutes,
  ...TeacherRoutes,
  ...UserRoutes,
  ...AdminRoutes,
  {
    path: '/forbidden',
    component: () => import('../views/Forbidden.vue'),
  },
  {
    path: '*',
    component: () => import('../views/404.vue')
  },
  {
    path: '/404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/403',
    component: () => import('../views/403.vue')
  },
  {
    path: '/419',
    component: () => import('../views/419.vue')
  },
  {
    path: '/error',
    component: () => import('../views/ServerError.vue')
  },
]

if(window.location.origin == process.env.VUE_APP_MAIN_DOMAIN){
  routes.push(...SuperRoutes)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  window.scrollTo(0, 0);
  
  domain({ next })

  if (!isDifferentRoute(to, from)) {
    return; 
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = { from, next, to };
    const nextMiddleware = middlewarePipeline(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }
  return next();
  
});


function isDifferentRoute(route1, route2) {
  return route1.name !== route2.name || JSON.stringify(route1.query) !== JSON.stringify(route2.query);
}

export default router
