import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './shared'

Vue.config.productionTip = false

if('serviceWorker' in navigator) {
  window.addEventListener('load', async() => {
    try {
      let reg;

      if (process.env.ENV === 'DEV') {
        reg = await navigator.serviceWorker.register('/service-worker.js', {
          type: 'module',
        });
      } else {
        reg = await navigator.serviceWorker.register('/service-worker.js');
      }

      console.log('Service worker registered!');
    } catch (err) {
      console.log('😥 Service worker registration failed: ', err);
    }
  })
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
