export function userGetters (state) {
  return state.user
}

export function errorsGetters (state) {
  return state.errors
}

export function tenantGetters (state) {
  return state.tenant
}