import Vue from 'vue'

Vue.component('toaster', () => import('../components/reusable/Toaster.vue'))
Vue.component('alert', () => import('../components/reusable/Alert.vue'))
Vue.component('textfield', () => import('../components/reusable/forms/TextField.vue'))
Vue.component('circular', () => import('../constants/reusable/CircularProg.vue'))
Vue.component('FormUploadFile', () => import('../components/reusable/forms/FormUploadFile.vue'))
Vue.component('FormTextFieldRequired', () => import('../components/reusable/forms/FormTextFieldRequired.vue'))
Vue.component('FormTextFieldOptional', () => import('../components/reusable/forms/FormTextFieldOptional.vue'))
Vue.component('FormTextAreaRequired', () => import('../components/reusable/forms/FormTextAreaRequired.vue'))
Vue.component('FormTextAreaOptional', () => import('../components/reusable/forms/FormTextAreaOptional.vue'))
Vue.component('FormTextDisplay', () => import('../components/reusable/forms/FormTextDisplay.vue'))
Vue.component('FormSelectObject', () => import('../components/reusable/forms/FormSelectObject.vue'))
Vue.component('FormSelectArray', () => import('../components/reusable/forms/FormSelectArray.vue'))
Vue.component('FormLabel', () => import('../components/reusable/forms/FormLabel.vue'))
Vue.component('FormCheckbox', () => import('../components/reusable/forms/FormCheckbox.vue'))
Vue.component('FormMonthPicker', () => import('../components/reusable/forms/FormMonthPicker.vue'))
Vue.component('FormDatePicker', () => import('../components/reusable/forms/FormDatePicker.vue'))
Vue.component('FormPagination', () => import('../components/reusable/forms/FormPagination.vue'))
Vue.component('LinearProgress', () => import('../components/reusable/LinearProgress.vue'))
Vue.component('SearchBar', () => import('../components/reusable/SearchBar.vue'))
Vue.component('DialogBox', () => import('../components/reusable/DialogBox.vue'))
Vue.component('ButtonPrimary', () => import('../components/reusable/ButtonPrimary.vue'))
Vue.component('ButtonExport', () => import('../components/reusable/ButtonExport.vue'))
Vue.component('Tabs', () => import('../components/reusable/Tabs.vue'))
Vue.component('FilterMenu', () => import('../components/reusable/FilterMenu.vue'))
Vue.component('RichTextEditor', () => import('../components/reusable/Editor.vue'))